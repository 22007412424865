import React from 'react';
import logo from './logo.svg';
import './App.css';

import Root from "./components/Root/Root";
import HomePage from './components/HomePage/HomePage';
import Contact from "./components/Contact/Contact";
import Massages from './components/Massages/Massages';
import WieBenIk from './components/WieBenIk/WieBenIk';
import Fotos from './components/Fotos/Fotos';
import Cadeaubon from './components/Cadeaubon/Cadeaubon';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root/>,
      children: [
        {
          path: "",
          element: <HomePage/>
        },
        {
          path: "wiebenik",
          element: <WieBenIk/>
        },
        {
          path: "massages",
          element: <Massages/>
        },
        {
          path: "cadeaubon",
          element: <Cadeaubon/>
        },
        {
          path: "fotos",
          element: <Fotos/>
        },
        {
          path: "contact",
          element: <Contact/>
        }
      ]
    }
  ])
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
