import React, { useState, useRef } from "react";
import styles from "./Contact.module.css";
//import emailjs from "emailjs-com";
import emailjs from '@emailjs/browser';

const Contact = () => {
    const [name, setUser_name] = useState("");
    const [email, setUserEmail] = useState("");
    const [comment, setComment] = useState("");
    const [submitted, setSubmitted] = useState(false);
    
    const sendEmail = () => {
        const emailContent = {
            name: name,
            email: email,
            comment: comment
        };
        setSubmitted(true);
        emailjs.send('service_xyn2o3l', 'template_i0epa64', emailContent, 'JIrwgowSKUisxxkWn')
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });   
    };
    if (submitted) {
        return (
          <>
            <div>Bedankt voor je bericht!</div>
            <div>Ik neem zo snel mogelijk contact met je op.</div>
          </>
        );
    };
    return(
        <>
        <main>
            <html>
                <header>
                
                
                </header>
                <h1>Contact en reservatie</h1>
                <p>Een afspraak maken is mogelijk via sms, telefoon of mail</p>
                <p>Gsm:  0498 06 15 54</p>
                <form 
                    className={styles.contactform}
                    onSubmit={sendEmail}
                >
                    <div>
                        <input 
                            className={styles.textbox}
                            type="text"
                            placeholder="Naam"
                            name="name"
                            required
                            onChange={(event) => {setUser_name(event.target.value)}} />
                    </div>
                    <div>
                        <input 
                            className={styles.textbox}
                            type="email"
                            placeholder="Email"
                            name="email"
                            required 
                            onChange={(event) => {setUserEmail(event.target.value)}}/>
                    </div>
                    <div>
                        <textarea 
                            className={styles.textbox}
                            placeholder="Graag afspraak op:"
                            name="comment"
                            required 
                            onChange={(event) => {setComment(event.target.value)}}/>
                    </div>
                    
                    <button className={styles.btn} onClick={sendEmail} value="Send">Send message</button>
                </form>
                <br/>
                <div className={styles.map}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2499.3912859135953!2d4.58706091562411!3d51.211867179587756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3fedee9a7fddf%3A0xec81a5b4c9dcdc50!2sMeir%2036%2C%202520%20Ranst!5e0!3m2!1sen!2sbe!4v1678642583744!5m2!1sen!2sbe" width="600" height="450" style={{border:0}} allowFullScreen aria-hidden="false" loading="lazy" ></iframe>
                </div>

                
                <footer className={styles.contactFooter}>
                <p>Meir 36, Ranst | 0498 06 15 54 | info@relassati.be | BE0648954249</p>        
                </footer>


            </html>
        </main>
        </>
    );
}
export default Contact;