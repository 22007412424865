import styles from "./HomePage.module.css";
import HomeImage from './assets/HomeScreen.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomePage = () => {
    
    return(
        <>
        <main>
            
            <div className={styles.landingText}>
            <h1>
            Welkom bij ‘Relassati’
            </h1>   
            <p>De betekenis is simpel maar zegt alles: ‘Ontspan’. Kijk maar eens rond, je vindt er alle info terug via mijn keuzemenu. Hopelijk ontmoet ik je snel eens!</p>
            </div>
            
            <img src={HomeImage} className={styles.image}></img>
            <br/>
            <br/>
            <br/>
        </main>
        <ToastContainer/>
        </>
        
    );
}

export default HomePage;