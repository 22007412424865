import styles from "./WieBenIk.module.css";
import wiebenik from './assets/wiebenik.jpg';

const WieBenIk = () => {
    return(
        <main>
            <h1>Wie ben ik</h1>
            <img src={wiebenik} className={styles.wiebenik}/>
            <div className={styles.centeredText}>
                <p>Hoi, ik ben Inge, en ik werk reeds heel wat jaren als verpleegkundige.  Mijn huidige werkplek is  het zorgtraject in ZNA Jan Palfijn Merksem.</p>
                <p>Daar gebeurt zowat alles wat bij de voorbereiding van je operatie komt kijken.</p>
                <p>Een leuke job met toffe collega’s!  Maar wat ik nog liever doe is masseren 😊 </p>
                <p>Ik volgde in 2016 de opleiding tot massagetherapeut, en heb al heel wat ervaring ondertussen.</p>
                <p>Ik heb ook een bijkomende cursus Vunkuwa  of Afrikaanse massage gevolgd, waarbij meer met vuisten, armen en ellebogen gemasseerd wordt. </p>
                <p>Dat kan ik goed toepassen als je van een diepere massage houdt.</p>
                <p>Ik word gelukkig van anderen te laten genieten, en door te masseren word ik helemaal mee zen. </p>
                <p>Het is voor mij een inspanning en een ontspanning samen.  Laat me weten wat je noden zijn, zodat ik je een massage op jouw maat kan geven. </p>
            </div>
                
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </main>
        
    );
}

export default WieBenIk;