import styles from "./Fotos.module.css";
import handdoek from './assets/handdoek.jpg';
import beleltjes from './assets/belletjes.jpg';
import decoratie from './assets/decoratie.jpg';
import schaaldieren from './assets/schaaldieren.jpg';
import massage1 from './assets/massage1.jpg';
import massage2 from './assets/massage2.jpg';
import massage3 from './assets/massage3.jpg';
import massage4 from './assets/massage4.jpg';
import massage5 from './assets/massage5.jpg';
import massage6 from './assets/massage6.jpg';
import tafel from './assets/tafel.jpg';
const Fotos = () => {
    return(
        <main>
            <h1>Foto's</h1>
            <div className={styles.row}>
            <img src={tafel} className={styles.image}></img>
            <img src={handdoek} className={styles.image}></img>
            <img src={decoratie} className={styles.image}></img>
            
            <img src={massage2} className={styles.image}></img>
            <img src={massage1} className={styles.image}></img>
            <img src={massage4} className={styles.image}></img>

            <img src={schaaldieren} className={styles.image}></img>
            <img src={beleltjes} className={styles.image}></img>
            <img src={massage3} className={styles.image}></img>
            
            <img src={massage5} className={styles.image}></img>
            <img src={massage6} className={styles.image}></img>
            </div>
            <br/>
            <br/><br/>
            <br/>

            
        </main>
    );
}

export default Fotos;