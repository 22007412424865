import styles from "./Cadeaubon.module.css";
import giftcard from './assets/giftcard.jpg';
const Cadeaubon = () => {
    return(
        <main>
            <h1>Cadeaubon</h1>
            <p>Zoek je nog een origineel geschenk voor iemand?  Wat is er leuker dan een ontspannende massage cadeau krijgen?  Bon bij mij te verkrijgen of kan naar jou verzonden worden.</p>
            <img src={giftcard} className={styles.giftcard}></img>
        </main>
    );
}

export default Cadeaubon;