import styles from "./Massages.module.css";
import pay from './assets/Payconiq.png';
import massage7 from './assets/massage7.jpg';
const Massages = () => {
    return(
        <main>
            <h1>Massages en prijzen</h1>
            <img src={massage7} className={styles.mpImage}></img>
            <h2>Ontspannende lichaamsmassage:</h2>
            <p>Geeft positief effect op lichaam en geest.  De Zweedse massage is relaxerend en behandelend.  Ze is geschikt voor iedereen met pijn en blokkades, maar ook voor iedereen die een ontspannende massage wil krijgen. </p>
            <p>Volledige lichaamsmassage: 1 uur:  50 €</p>

            <h2>Rug, nek en hoofd: </h2>
            <p>30 minuten:  35€</p>

            <h2>Gelaat en hoofd: </h2>
            <p>Spanningen kunnen leiden tot hoofdpijn, vermoeide ogen.  Een hoofdmassage kan deze klachten verminderen.</p>
            <p>20 min: 25€ </p>

            <h2>Voetmassage:</h2>
            <p>Door een aangename en rustige massage van je benen en voeten kom je een stuk lichter van de massagetafel.</p>
            <p>20 min: 25 €</p>
            <br></br>
            <h2>Betalen</h2>
            <p>U heeft de mogelijkheid om te betalen met cash of Payconiq.</p>
            <img src={pay} style={{width:"100px", marginLeft:"10px"}}></img>
            <br/>
            <br/>
        </main>
    );
}

export default Massages;