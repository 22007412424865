import styles from "./Root.module.css";
import {Outlet, NavLink, useHref} from "react-router-dom";


const Root = () => {
    return(
        <>
        <header>
                <p className={styles.discount}>1ste massage 10% korting</p>
            
            <div className={styles.header}>
                <div className={styles.mobileDisplay}>
                    <div className={styles.dropdown}>
                        <button className={styles.dropbtn}>Menu</button>
                        <div className={styles.dropdowncontent}>
                            <a href="/">Home</a>
                            <a href="/wiebenik">Wie ben ik</a>
                            <a href="/massages">Massages en prijzen</a>
                            <a href="/cadeaubon">Cadeaubon</a>
                            <a href="/fotos">Foto's</a>
                            <a href="/contact">Contact en reservatie</a>
                        </div>
                    </div>
                </div>
                <h1 className={styles.logo}>Relassati</h1>
                <p className={styles.PhoneRight}>+32 498 06 15 54</p>
            </div>
            <nav className={styles.topNavigation}>
                <div className={styles.desktopDisplay}>
                    <ul>
                        <li><NavLink className={({isActive}) => isActive ? styles.active :      styles.notactive} to="/">Home</NavLink></li>

                        <li><NavLink className={({isActive}) => isActive ? styles.active :      styles.notactive} to="/WieBenIk">Wie ben ik</NavLink></li>

                        <li><NavLink className={({isActive}) => isActive ? styles.active :      styles.notactive} to="/Massages">Massages en prijzen</NavLink></li>

                        <li><NavLink className={({isActive}) => isActive ? styles.active :      styles.notactive} to="/Cadeaubon">Cadeaubon</NavLink></li>

                        <li><NavLink className={({isActive}) => isActive ? styles.active :      styles.notactive} to="/Fotos">Foto's</NavLink></li>

                        <li><NavLink className={({isActive}) => isActive ? styles.active :      styles.notactive} to="/Contact">Contact en reservatie</NavLink></li>

                    </ul>
                </div>
                

            </nav>
            
        </header>
        <footer>
            <p>Meir 36, Ranst | 0498 06 15 54 | info@relassati.be | BE0648954249</p>        
        </footer>
        <Outlet/>
        </>
    )
}

export default Root;